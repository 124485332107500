<template>
  <div class="banner">
    <!-- <div class="bannerText">
      <div class="webMain">
        <p class="pTitle">一站式专业服务，顶级方案规划</p>
      <p class="pPrompt">助力企业全方面升级</p>
      </div>
    </div> -->
    <template>
      <el-carousel :interval="3000" arrow="always">
        <el-carousel-item v-for="item in imgList" :key="item.id">
          <div class="promptText">
            <div class="webMain">
              <p class="Title">{{ item.Title }}</p>
              <p class="Subtitle" v-if="item.SubTitle">{{ item.SubTitle }}</p>
              <span class="border"></span>
              <p class="Text">{{ item.Text }}</p>
            </div>
          </div>
          <img :src="$store.state.requestAPI + item.path" alt="">
        </el-carousel-item>
      </el-carousel>
    </template>
    <!-- banner区域 -->
    <!-- <img src="../../assets/web/index/banner.png" alt=""> -->
  </div>
</template>

<script>
import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
  data() {
    return {
      imgList: [
        { id: 1, imgPath: 'banner.png' },
        { id: 2, imgPath: 'banner.png' },
        { id: 3, imgPath: 'banner.png' },

      ],
      contentList: [
        { Title: '一站式专业服务，顶级方案规划', Text: '助力企业全方面升级' },
        { Title: '骏领国际商务', Text: '让服务成为首要选择' },
        {
          Title: '推动管理进步，服务企业成功', Text: '值得信赖的企业管家', SubTitle: '专业/professional 高效/efficiency'
        }
      ]
    }
  },
  methods: {
    // 获取附件列表
    async getList() {
      let info = { pageSize: 3, pageNum: 1, categoryId: this.$store.state.imgPath.index }
      const res = await attachmentListAPI(info);
      console.log(res);
      if (res.code === 200) {
        let list = res.data.list;

        this.imgList = list.map((item, index) => {
          // console.log(this.contentList[index])
          return { ...item, ...this.contentList[index] }
        })
        // this.imgList = list;
        console.log(this.imgList)
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '列表获取失败!'
        });
      }

    },
  },
  created() {
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
.banner {
  height: 420px;

  
  ::v-deep .el-carousel__container {
    height: 420px !important;

  }

  ::v-deep .el-carousel--horizontal {
    z-index: 0;
  }

  .el-carousel__item img {
    height: 100%;
  }

  ::v-deep .el-carousel__indicator--horizontal {
    padding: 15px 13px;
  }

  ::v-deep .el-carousel__indicator.is-active button {
    background-color: var(--custom-color1);
  }

  ::v-deep .el-carousel__button {
    background-color: white;
    opacity: 1;
    height: 20px;
    width: 20px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.34);
    border: 2px solid var(--custom-color1);
    border-radius: 50%;
  }

  // .el-carousel__item:nth-child(2n) {
  //   background-color: #99a9bf;
  // }

  // .el-carousel__item:nth-child(2n+1) {
  //   background-color: #d3dce6;
  // }
}
</style>